import React from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Link from "gatsby-link"

import { Frame, TitledSection } from "../../layout"
import { ArticleList, Header } from "../../compositions"
import { toSlug } from "../../../helpers"
import { PageHeader, PageSubheading, Accordion, Item } from "../../components"
import { Image, H2, Detail } from "../../elements"
import { SEO, MDXProvider } from "../../helpers"
import { graphql } from "gatsby"
import {
  ArticleQuery,
  GraphCms_Article,
  GraphCms_Page,
  GraphCms_Dataset,
} from "../../../../graphql-types"

export const query = graphql`
  query Article($id: String) {
    graphCmsArticle(id: { eq: $id }) {
      ...ArticleFields
    }
    allGraphCmsArticle(sort: { fields: createdAt, order: DESC }, limit: 8) {
      nodes {
        ...ArticleFields
      }
    }
  }
`

type Props = {
  data: ArticleQuery
}

const Article = ({ data }: Props) => {
  const article = data.graphCmsArticle as GraphCms_Article

  const headings = article.page!.markdown!.childMdx?.tableOfContents.items.map(
    (heading: any) => ({
      key: heading.title,
      id: "#" + toSlug(heading.url),
    })
  )

  if (article.page!.faq.length > 0) {
    headings.push({ key: "Sıkça Sorulan Sorular", id: "#faq" })
  }

  return (
    <Frame>
      <SEO page={article.page as GraphCms_Page} />
      <Header className="z-10" />
      <PageHeader title={article.title} page={article.page!} />
      <div className="max-w-screen-md mx-auto px-3 sm:px-4">
        {article.page!.image && (
          <Image
            className="block mb-10"
            gatsbyImageData={
              article.page!.image.localFile?.childImageSharp?.gatsbyImageData
            }
            alt={article.page!.title}
          />
        )}

        <div className="bg-blue-light p-5">
          <Detail className="mb-4 text-lg text-black font-bold">İndeks:</Detail>
          <ul className="pl-4">
            {headings.map((heading: any) => {
              return (
                <li
                  key={heading.key}
                  className="list-outside list-decimal mb-4 text-base hover:underline"
                >
                  <Link to={`${article.page!.url?.pathname}${heading.id}`}>
                    {heading.key}
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>

        {article.page!.markdown && (
          <MDXProvider>
            <MDXRenderer>
              {article.page!.markdown?.childMdx!.body ?? ""}
            </MDXRenderer>
          </MDXProvider>
        )}

        {article.page!.faq.length > 0 && (
          <PageSubheading id="faq" className="bg-black mt-10 px-2 py-2">
            <H2 className="text-white text-xl font-bold">
              Sıkça Sorulan Sorular
            </H2>
          </PageSubheading>
        )}

        {article.page!.faq.length > 0 && (
          <Accordion>
            {article.page!.faq.map((faq: GraphCms_Dataset) => {
              return (
                <Item key={faq.id} title={faq.key}>
                  <Detail className="text-gray-dark paragraph pb-10">
                    {faq.value}
                  </Detail>
                </Item>
              )
            })}
          </Accordion>
        )}
      </div>
      <TitledSection
        title="Yurt Dışında Yaşamak için Bilmeniz Gerekenler"
        tagline="Yurt dışında yaşamaya karar vermeden önce bilmemiz gereken bazı önemli şeyler vardır. Sizler için onları derledik."
        label="Yurt Dışında Yaşam Hakkında Bilgiler &amp; Öneriler"
        to="/yazilar"
        className="mt-20 bg-blue-light"
      >
        <ArticleList
          articles={data.allGraphCmsArticle.nodes as GraphCms_Article[]}
        />
      </TitledSection>
    </Frame>
  )
}

export default Article
